import { Backend } from '../backend'
import { Context } from '../context'
import { DiceRoll } from 'rpg-dice-roller'

export default class RollCommand {
  static execute(input) {
    const roll = new DiceRoll(input)
    Backend.createCampaignChild("Message", {
      messageType: "RollMessage",
      body: roll.output,
      members: ["*"],
      editors: [Context.user.username]
    })
  }
}
