import EditableField from '../EditableField'

export default function ActionDamageFormula({setObject, action, editing}) {
  if (!editing && (action.averageDamage <= 1 || action.damageDiceSize === 1)) {
    return 1
  }
  return (<span>
    {action.averageDamage} (
      <EditableField setObject={setObject} attribute="damageDiceCount" editing={editing} decorator={action} />
      d
      <EditableField setObject={setObject} attribute="damageDiceSize" editing={editing} decorator={action} />
      {action.damageModifier < 0 ? " - " : " + "}
      {Math.abs(action.damageModifier)}
    )
  </span>)
}
