import { v4 as uuidv4 } from 'uuid';

function compare( a, b ) {
  const aname = (a.name || a.createdAt).toLowerCase()
  const bname = (b.name || b.createdAt).toLowerCase()
  if ( aname < bname ){
    return -1;
  }
  if ( aname > bname ){
    return 1;
  }
  return 0;
}

export default class DataStore {
  status = "stopped"
  objectsById = {}
  idsByType = {}
  subscriptions = new Map()

  initializeType(type) {
    if (!this.idsByType[type]) {
      this.idsByType[type] = new Set()
    }
  }

  clear() {
    this.objectsById = {}
    this.idsByType = {}
    this.subscriptions.forEach((array) => {
      array.forEach(subscription => subscription.callback())
    })
  }

  get(id) {
    return this.objectsById[id]
  }

  set(object) {
    this.objectsById[object.id] = object
    this.initializeType(object.type)
    this.idsByType[object.type].add(object.id)
    this.triggerEvents(object.id, object.type, object)
  }

  load(type, object) {
    object.type = type
    if (object._deleted) {
      this.remove(object)
    }
    else {
      this.set(object)
    }
  }

  bulkLoad(type, objects) {
    for (const object of objects) {
      this.load(type, object)
    }
  }

  remove(object) {
    if (!this.objectsById[object.id]) {
      return
    }
    delete this.objectsById[object.id]
    this.idsByType[object.type]?.delete(object.id)
    this.triggerEvents(object.id, object.type, null)
  }

  objectsByType(type) {
    this.initializeType(type)
    return Array.from(this.idsByType[type], (id) => this.objectsById[id]).sort(compare)
  }

  triggerEvents(objectId, objectType, object) {
    this.subscriptionsByObjectId(objectId)?.forEach((subscription) => {
      subscription.callback(object)
    })
    this.subscriptionsByObjectId(objectType)?.forEach((subscription) => {
      subscription.callback()
    })
  }

  subscriptionsByObjectId(objectId, initialize=false) {
    let subscriptions = this.subscriptions.get(objectId)
    if (initialize && !subscriptions) {
      subscriptions = new Map()
      this.subscriptions.set(objectId, subscriptions)
    }
    return subscriptions
  }

  subscribe(objectIdOrType, callback) {
    const subscription = { callback, objectId: objectIdOrType, subscriptionId: uuidv4() }
    this.subscriptionsByObjectId(objectIdOrType, true).set(subscription.subscriptionId, subscription)
    return subscription
  }

  unsubscribe(subscription) {
    this.subscriptionsByObjectId(subscription.objectId)?.delete(subscription.subscriptionId)
  }

  async start() {
    this.updateStatus("starting")
    this.apiSubscriptions = await Promise.all(this.buildApiSubscriptions())
    await this.fetchApiData()
    this.updateStatus("running")
  }

  stop() {
    this.updateStatus("stopping")
    this.unsubscribeFromApi()
    this.clear()
    this.updateStatus("stopped")
  }

  updateStatus(status) {
    this.status = status
    this.triggerEvents("Status", "DataStoreStatus", status)
  }

  unsubscribeFromApi() {
    this.apiSubscriptions?.forEach((subscription) => {
      subscription.unsubscribe()
    })
    this.apiSubscriptions = []
  }
}
