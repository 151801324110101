import DataStore from './dataStore'
import {Context} from './context'
import { Backend } from './backend'

class _GlobalDataStore extends DataStore {
  set focusCampaignId(value) {
    this._focusCampaignId = value
    const match = this.get(value)
    if (match) {
      this.set({...match, id: 'CurrentCampaign', type: '_CurrentCampaign'})
    }
    else {
      this.remove({id: 'CurrentCampaign', type: '_CurrentCampaign'})
    }
  }

  set(object) {
    if (object?.id === this._focusCampaignId) {
      this.set({...object, id: 'CurrentCampaign', type: '_CurrentCampaign'})
    }
    super.set(object)
  }

  remove(object) {
    if (object && object.id === this._focusCampaignId) {
      this.remove({id: 'CurrentCampaign', type: '_CurrentCampaign'})
    }
    super.remove(object)
  }

  async fetchApiData() {
    this.bulkLoad("Campaign", await Backend.listCampaigns())
  }

  buildApiSubscriptions() {
    return [
      Backend.subscribe(
        "Campaign",
        "onMutateCampaign",
        { owner: Context.user.username },
        (campaign) => {
          this.load("Campaign", campaign)
        }
      )
    ]
  }
}

export const GlobalDataStore = new _GlobalDataStore()
