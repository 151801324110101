import DataStore from './dataStore'
import {Backend} from './backend'
import {GlobalDataStore} from './globalDataStore'
import {CampaignDataStore} from './campaignDataStore'
import {SceneDataStore} from './sceneDataStore'
import { Auth } from '@aws-amplify/auth'

class _Context extends DataStore {
  start() {
    this.statusCallback = () => {}
    this.globalStatusSubscription = GlobalDataStore.subscribe("Status", (status) => {
      this.globalStatus = status
      this.calculateStatus()
    })
    this.campaignStatusSubscription = CampaignDataStore.subscribe("Status", (status) => {
      this.campaignStatus = status
      this.calculateStatus()
    })
    this.sceneStatusSubscription = SceneDataStore.subscribe("Status", (status) => {
      this.sceneStatus = status
      this.calculateStatus()
    })
    this.userSubscription = this.subscribe("User", () => {
      this.calculateStatus()
    })
    Auth.currentUserInfo().then((userInfo) => {
      this.user = userInfo
    })
  }

  calculateStatus() {
    if (this.user &&
      this.globalStatus !== 'starting' &&
      this.campaignStatus !== 'starting' &&
      this.sceneStatus !== 'starting') {
      this.statusCallback(true)
      return true
    }
    else {
      this.statusCallback(false)
      return false
    }
  }

  set user(user) {
    user.type = "User"
    if (user.id !== this._userId) {
      this._userId = user.id
      GlobalDataStore.stop()
      this.set(user)
      GlobalDataStore.start()
    }
  }

  get user() {
    return this.objectsByType("User")[0]
  }

  get campaign() {
    return this.objectsByType("Campaign")[0]
  }

  get isOwner() {
    return this.user?.username === this.campaign?.owner
  }

  clearCampaign() {
    if (this.campaignId) {
      this.remove({id: this.campaignId, type: "Campaign"})
      this.campaignId = null
    }
  }

  set campaign(campaign) {
    if (campaign.id !== this.campaignId) {
      this.remove({id: this.campaignId, type: "Campaign"})
      if (this.campaignSubscription) {
        CampaignDataStore.unsubscribe(this.campaignSubscription)
      }
      this.campaignId = campaign.id
      this.campaignSubscription = GlobalDataStore.subscribe(campaign.id, (campaign) => {
        if (campaign) {
          this.set(campaign)
        }
        else {
          this.remove({id: this.campaignId, type: 'Campaign'})
        }
      })

      campaign.type = "Campaign"
      CampaignDataStore.stop()
      GlobalDataStore.set(campaign)
      CampaignDataStore.start()
    }
  }

  set sceneId(sceneId) {
    if (sceneId !== this._sceneId) {
      this._sceneId = sceneId
      SceneDataStore.stop()
      SceneDataStore.start(sceneId)
    }
  }

  stop() {
    GlobalDataStore.unsubscribe(this.globalStatusSubscription)
    CampaignDataStore.unsubscribe(this.campaignStatusSubscription)
    SceneDataStore.unsubscribe(this.sceneStatusSubscription)
    GlobalDataStore.stop()
    CampaignDataStore.stop()
    SceneDataStore.stop()
    this.unsubscribe(this.userSubscription)
    this.remove(this.user)
  }

  buildApiSubscriptions() {
    return [
      Backend.subscribe(
        "Campaign",
        "onMutateCampaignById",
        { id: this.campaignId },
        (campaign) => {
          GlobalDataStore.load("Campaign", campaign)
        }
      )
    ]
  }
}

export const Context = new _Context()
