export default class MonsterTraitDecorator {
  constructor(monsterDecorator, data, traits) {
    this.monsterDecorator = monsterDecorator
    this.data = data
    this.data.type = 'MonsterTrait'
    if (this.data.trait) {
      this.definition = traits.find(trait => trait.id === this.data.trait)
    }
  }

  get monster() {
    return this.monsterDecorator
  }

  get object() {
    return this.data
  }

  get name() {
    return this.data.name || this.definition?.name
  }

  get description() {
    return this.data.description || this.definition?.description
  }

  get key() {
    return this.data.key
  }
}
