import Action from './Action'
import buildObject from "../FormSchemaFactory"

export default function ActionsSection({monster, setObject, editing}) {
  const addAction = (e) => {
    e.preventDefault()
    const actions = monster.object.actions || []
    const object = buildObject('Action')
    if (monster.dexterity > monster.strength) {
      object.attackAbility = "dexterity"
    }
    else {
      object.attackAbility = "strength"
    }
    actions.push(object)
    setObject({...monster.object, actions})
  }

  const setAction = (action) => {
    const actions = monster.object.actions || []
    const index = actions.findIndex(item => item.id === action.id)
    if (index >= 0) {
      actions[index] = action
    }
    else {
      actions.push(action)
    }
    setObject({...monster.object, actions})
  }

  const removeAction = (action) => {
    let actions = monster.object.actions || []
    actions = actions.filter(item => item.id !== action.id)
    setObject({...monster.object, actions})
  }

  if (editing || monster.actions.length > 0) {
    return <section>
      <div className='d-flex'>
        <h4>Actions</h4>
        {editing ?
          <button className='btn ms-auto' onClick={addAction}>➕</button> :
          null
        }
      </div>
      {
        monster.actions.map((action, index) => {
          return <Action key={`action_${index}`} action={action} setObject={setAction} editing={editing} removeAction={removeAction} />
        })
      }
    </section>
  }
  else {
    return null
  }
}
