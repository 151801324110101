import { useObject } from '../reactDataStore'
import { useParams, useLocation } from 'react-router-dom'
import LoadingIndicator from './LoadingIndicator'
import * as Schema from "./FormSchema"
import ItemBlock from "./item/Block"
import MonsterBlock from "./monster/Block"

function buildContentBlocks(schema, object) {
  return schema.fields.map((field) => {
    if (!field.form) {
      return null
    }

    return(
      <div key={`${field.key}`} className="mb-3">
        <label className="form-label">{field.form.label}</label>
        <div>{object[field.key]}</div>
      </div>
    )
  })
}

function useQuery(key) {
  const params = new URLSearchParams(useLocation().search)
  return params.get(key)
}

export default function CampaignChildRouter() {
  const type = useQuery("objectType")
  const { objectId } = useParams()
  const object = useObject('campaign', objectId)
  if (!object) {
    return <LoadingIndicator/>
  }

  if (type === 'Monster') {
    return <MonsterBlock object={object} />
  }
  if (type === 'Item') {
    return <ItemBlock object={object} />
  }
  return <CampaignChildDetails object={object}/>
}

function CampaignChildDetails({object}) {
  const schema = Schema[object.type]
  const contentBlocks = buildContentBlocks(schema, object)
  return (
    <div>
      <h4>{object.type} - {object.name}</h4>
      {contentBlocks}
    </div>
  )
}
