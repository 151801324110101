import DataStore from './dataStore'
import { Context } from './context'
import { Backend } from './backend'

const childTypes = [
  "Character",
  "Player",
  "Space",
  "Message",
  "Mechanic",
  "Monster",
  "Language",
  "Skill",
  "DamageType",
  "Condition",
  "CreatureType",
  "Trait",
  "Item"
]

class _CampaignDataStore extends DataStore {
  async start() {
    this.activityInterval = window.setInterval(() => {Backend.markPlayerActive()}, 60000)
    await super.start()
    Backend.markPlayerActive()
  }

  stop() {
    window.clearInterval(this.activityInterval)
    super.stop()
  }

  async fetchApiData() {
    for (const type of childTypes) {
      this.bulkLoad(type, await Backend.listCampaignChildren(type))
    }
  }

  load(type, object) {
    object.type = type
    if (
      Context.isOwner ||
      (object.members || []).includes("*") ||
      (object.members || []).includes(Context.user.username) ||
      (object.editors || []).includes("*") ||
      (object.editors || []).includes(Context.user.username)
    ) {
      if (object._deleted) {
        this.remove(object)
      }
      else {
        this.set(object)
      }
    }
    else {
      this.remove(object)
    }
  }

  buildApiSubscriptions() {
    return childTypes.map((type) => {
      return Backend.subscribe(
        type,
        `onMutate${type}`,
        { campaign: Context.campaignId },
        (object) => {
          this.load(type, object)
        }
      )
    })
  }
}

export const CampaignDataStore = new _CampaignDataStore()
