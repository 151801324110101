import { useObjectsOfType, useContext } from '../reactDataStore'
import { useState } from 'react'
import { Backend } from '../backend'
import { execute } from '../commands'
import { reportError } from '../errorReporter'

export default function MessagePanel() {
  let formElement
  const context = useContext()
  const messages = useObjectsOfType('campaign', "Message")
  const [newMessage, setNewMessage] = useState("")
  function createMessage(e) {
    e.preventDefault()
    if (newMessage.startsWith('/')) {
      const commandRegex = /\/(?<command>\w+)( (?<input>.*))?/i
      const match = newMessage.match(commandRegex)
      if (match) {
        const command = match.groups.command
        const input = match.groups.input
        execute(command, input)
      }
      else {
        reportError(`Unexpected command: ${newMessage}`)
      }
      return
    }

    Backend.createCampaignChild("Message", {
      messageType: "ChatMessage",
      body: newMessage,
      members: ["*"],
      editors: [context.user.username]
    })
    setNewMessage("")
  }
  setTimeout(() => {
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" })
    }
  }, 0)
  return (
    <div className='message-panel'>
      {messages.map((message) => {
        return (
          <div key={`message_${message.id}`}>
            {message.body}
          </div>
        )
      })}
      <form id="message-form" onSubmit={createMessage} className='d-flex' ref={(el) => { formElement = el }}>
        <input
          type="text"
          className="form-control"
          onChange={e => setNewMessage(e.target.value)}
          value={newMessage}
        />
        <button className="btn btn-primary btn-sm" type="submit">
          Send
        </button>
      </form>
    </div>
  )
}
