import { Context } from './context'
import { GlobalDataStore } from './globalDataStore'
import { CampaignDataStore } from './campaignDataStore'
import { SceneDataStore } from './sceneDataStore'
import { useEffect, useState } from 'react'

export function useCampaign() {
  return useObjectsOfType('context', "Campaign")[0]
}

export function useUser() {
  return useObjectsOfType('context', "User")[0]
}

export function useContext() {
  const user = useUser()
  const campaign = useCampaign()
  return {
    user: user,
    campaign: campaign,
    isOwner: user?.username === campaign?.owner
  }
}

export function useSubscription(dataStoreKey, objectId, callback) {
  useEffect(() => {
    const dataStore = dataStoreMap[dataStoreKey]
    const subscription = dataStore.subscribe(objectId, callback)
    return function cleanup() {
      dataStore.unsubscribe(subscription)
    }
  }, [dataStoreKey, objectId, callback])
}

const dataStoreMap = {
  global: GlobalDataStore,
  context: Context,
  campaign: CampaignDataStore,
  scene: SceneDataStore
}

export function useObject(dataStoreKey, objectId, onDelete) {
  const dataStore = dataStoreMap[dataStoreKey]
  const [object, setObject] = useState(dataStore.get(objectId))
  useEffect(() => {
    const dataStore = dataStoreMap[dataStoreKey]
    const obj = dataStore.get(objectId)
    if (object !== obj) {
      setObject(obj)
    }
  }, [dataStoreKey, objectId, object])
  useSubscription(dataStoreKey, objectId, (newObject) => {
    if (newObject) {
      if (newObject !== object) {
        setObject(newObject)
      }
    }
    else {
      if (object) {
        setObject(null)
        if (onDelete) {
          onDelete()
        }
      }
    }
  })
  return object
}

export function useObjectsOfType(dataStoreKey, type, extraContext) {
  const dataStore = dataStoreMap[dataStoreKey]
  const [objects, setObjects] = useState(dataStore.objectsByType(type))
  useEffect(() => {
    const dataStore = dataStoreMap[dataStoreKey]
    setObjects(dataStore.objectsByType(type))
    const subscription = dataStore.subscribe(type, () => {
      setObjects(dataStore.objectsByType(type))
    })
    return function cleanup() {
      dataStore.unsubscribe(subscription)
    }
  }, [dataStoreKey, type, extraContext])
  return objects
}
