import ObjectInputElement from '../ObjectInputElement'

export default function HitPoints({monster, setObject, editing}) {
  return <div>
    <label>Hit Points</label>
    {monster.hitPoints} (
    {editing ?
      <span><ObjectInputElement object={monster.object} setObject={setObject} attribute="hitDiceCount" />d
      <ObjectInputElement object={monster.object} setObject={setObject} attribute="hitDiceSize" /></span> :
      monster.hitDice
    }
    )
  </div>

}
