import { Link } from 'react-router-dom'
import { useObjectsOfType, useUser } from '../../reactDataStore'
import LoadingIndicator from '../LoadingIndicator'

export default function ListCampaignsPage() {
  const campaigns = useObjectsOfType('global', "Campaign")
  const user = useUser()

  if (!user) {
    return <LoadingIndicator />
  }
  return (
    <div>
      <h2>Campaigns</h2>
      <ul>
        {campaigns.map((campaign) => {
          return <li key={`campaign_${campaign.id}`}><Link to={`/campaigns/${campaign.id}`}>{campaign.name}</Link></li>
        })}
      </ul>
      <Link to="/campaigns/new" className="btn btn-secondary">➕ Campaign</Link>
      <p>
        To join someonee else's campaign, give them your Membership Code: {user.username}
      </p>
    </div>
  )
}
