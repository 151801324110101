import * as Schema from "../FormSchema"
import EditableField from '../EditableField'

export default function Speed({attribute, monster, setObject, editing, label, prepend}) {
  const schema = Schema[monster.object.type]
  const field = schema.fields.find((field) => field.key === attribute)
  if (label !== false) {
    label = true
  }
  if (editing || monster[field.key] > 0) {
    return <span>
      {prepend}
      {label ? `${field.form.label.toLowerCase()} ` : null}
      <EditableField decorator={monster} setObject={setObject} attribute={attribute} editing={editing} />
      {" "}ft.
    </span>
  }
  else {
    return null
  }
}
