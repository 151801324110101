export default class ItemDecorator {
  constructor(data) {
    this.data = data
  }

  get object() {
    return this.data
  }

  get type() {
    return this.data.type
  }

  get name() {
    return this.data.name
  }
}
