/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCharacter = /* GraphQL */ `
  mutation CreateCharacter(
    $input: CreateCharacterInput!
    $condition: ModelCharacterConditionInput
  ) {
    createCharacter(input: $input, condition: $condition) {
      id
      campaign
      name
      characterType
      attributes
      portrait
      token
      silhouette
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCharacter = /* GraphQL */ `
  mutation UpdateCharacter(
    $input: UpdateCharacterInput!
    $condition: ModelCharacterConditionInput
  ) {
    updateCharacter(input: $input, condition: $condition) {
      id
      campaign
      name
      characterType
      attributes
      portrait
      token
      silhouette
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharacter = /* GraphQL */ `
  mutation DeleteCharacter(
    $input: DeleteCharacterInput!
    $condition: ModelCharacterConditionInput
  ) {
    deleteCharacter(input: $input, condition: $condition) {
      id
      campaign
      name
      characterType
      attributes
      portrait
      token
      silhouette
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      campaign
      name
      username
      lastActiveAt
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      campaign
      name
      username
      lastActiveAt
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      campaign
      name
      username
      lastActiveAt
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      campaign
      name
      spaceType
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      campaign
      name
      spaceType
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      campaign
      name
      spaceType
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      campaign
      messageType
      body
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      campaign
      messageType
      body
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      campaign
      messageType
      body
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMechanic = /* GraphQL */ `
  mutation CreateMechanic(
    $input: CreateMechanicInput!
    $condition: ModelMechanicConditionInput
  ) {
    createMechanic(input: $input, condition: $condition) {
      id
      campaign
      name
      json
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMechanic = /* GraphQL */ `
  mutation UpdateMechanic(
    $input: UpdateMechanicInput!
    $condition: ModelMechanicConditionInput
  ) {
    updateMechanic(input: $input, condition: $condition) {
      id
      campaign
      name
      json
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMechanic = /* GraphQL */ `
  mutation DeleteMechanic(
    $input: DeleteMechanicInput!
    $condition: ModelMechanicConditionInput
  ) {
    deleteMechanic(input: $input, condition: $condition) {
      id
      campaign
      name
      json
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDamageType = /* GraphQL */ `
  mutation CreateDamageType(
    $input: CreateDamageTypeInput!
    $condition: ModelDamageTypeConditionInput
  ) {
    createDamageType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDamageType = /* GraphQL */ `
  mutation UpdateDamageType(
    $input: UpdateDamageTypeInput!
    $condition: ModelDamageTypeConditionInput
  ) {
    updateDamageType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDamageType = /* GraphQL */ `
  mutation DeleteDamageType(
    $input: DeleteDamageTypeInput!
    $condition: ModelDamageTypeConditionInput
  ) {
    deleteDamageType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCreatureType = /* GraphQL */ `
  mutation CreateCreatureType(
    $input: CreateCreatureTypeInput!
    $condition: ModelCreatureTypeConditionInput
  ) {
    createCreatureType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCreatureType = /* GraphQL */ `
  mutation UpdateCreatureType(
    $input: UpdateCreatureTypeInput!
    $condition: ModelCreatureTypeConditionInput
  ) {
    updateCreatureType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCreatureType = /* GraphQL */ `
  mutation DeleteCreatureType(
    $input: DeleteCreatureTypeInput!
    $condition: ModelCreatureTypeConditionInput
  ) {
    deleteCreatureType(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCondition = /* GraphQL */ `
  mutation CreateCondition(
    $input: CreateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    createCondition(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCondition = /* GraphQL */ `
  mutation UpdateCondition(
    $input: UpdateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    updateCondition(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCondition = /* GraphQL */ `
  mutation DeleteCondition(
    $input: DeleteConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    deleteCondition(input: $input, condition: $condition) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      campaign
      name
      ability
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      campaign
      name
      ability
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      campaign
      name
      ability
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTrait = /* GraphQL */ `
  mutation CreateTrait(
    $input: CreateTraitInput!
    $condition: ModelTraitConditionInput
  ) {
    createTrait(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTrait = /* GraphQL */ `
  mutation UpdateTrait(
    $input: UpdateTraitInput!
    $condition: ModelTraitConditionInput
  ) {
    updateTrait(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrait = /* GraphQL */ `
  mutation DeleteTrait(
    $input: DeleteTraitInput!
    $condition: ModelTraitConditionInput
  ) {
    deleteTrait(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMonster = /* GraphQL */ `
  mutation CreateMonster(
    $input: CreateMonsterInput!
    $condition: ModelMonsterConditionInput
  ) {
    createMonster(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      image
      strength
      dexterity
      constitution
      intelligence
      wisdom
      charisma
      size
      creatureType
      alignment
      armorBonus
      armorLabel
      hitDiceCount
      hitDiceSize
      speed
      climbSpeed
      burrowSpeed
      flySpeed
      swimSpeed
      darkvision
      blindsight
      challengeRating
      skills
      saves
      vulnerabilities
      resistances
      damageImmunities
      conditionImmunities
      languages
      traits {
        id
        trait
        name
        description
      }
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      reactions {
        id
        key
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMonster = /* GraphQL */ `
  mutation UpdateMonster(
    $input: UpdateMonsterInput!
    $condition: ModelMonsterConditionInput
  ) {
    updateMonster(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      image
      strength
      dexterity
      constitution
      intelligence
      wisdom
      charisma
      size
      creatureType
      alignment
      armorBonus
      armorLabel
      hitDiceCount
      hitDiceSize
      speed
      climbSpeed
      burrowSpeed
      flySpeed
      swimSpeed
      darkvision
      blindsight
      challengeRating
      skills
      saves
      vulnerabilities
      resistances
      damageImmunities
      conditionImmunities
      languages
      traits {
        id
        trait
        name
        description
      }
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      reactions {
        id
        key
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMonster = /* GraphQL */ `
  mutation DeleteMonster(
    $input: DeleteMonsterInput!
    $condition: ModelMonsterConditionInput
  ) {
    deleteMonster(input: $input, condition: $condition) {
      id
      campaign
      name
      description
      image
      strength
      dexterity
      constitution
      intelligence
      wisdom
      charisma
      size
      creatureType
      alignment
      armorBonus
      armorLabel
      hitDiceCount
      hitDiceSize
      speed
      climbSpeed
      burrowSpeed
      flySpeed
      swimSpeed
      darkvision
      blindsight
      challengeRating
      skills
      saves
      vulnerabilities
      resistances
      damageImmunities
      conditionImmunities
      languages
      traits {
        id
        trait
        name
        description
      }
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      reactions {
        id
        key
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      campaign
      name
      itemType
      description
      image
      armorBonus
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      campaign
      name
      itemType
      description
      image
      armorBonus
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      campaign
      name
      itemType
      description
      image
      armorBonus
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
