import EditableField from './EditableField'
import * as Schema from "./FormSchema"

export default function EditableList({attribute, decorator, setObject, editing, suggestions}) {
  const schema = Schema[decorator.object.type]
  const field = schema.fields.find((field) => field.key === attribute)

  if (editing || decorator[field.key].length > 0) {
    return <div>
      <label>{field.form.label}</label>
      <EditableField setObject={setObject} attribute={attribute} editing={editing} decorator={decorator} suggestions={suggestions} />
    </div>
  }
  else {
    return null
  }
}
