/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMutateCampaign = /* GraphQL */ `
  subscription OnMutateCampaign($owner: String!) {
    onMutateCampaign(owner: $owner) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onMutateCampaignById = /* GraphQL */ `
  subscription OnMutateCampaignById($id: String!) {
    onMutateCampaignById(id: $id) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onMutateCharacter = /* GraphQL */ `
  subscription OnMutateCharacter($campaign: String!) {
    onMutateCharacter(campaign: $campaign) {
      id
      campaign
      name
      characterType
      attributes
      portrait
      token
      silhouette
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutatePlayer = /* GraphQL */ `
  subscription OnMutatePlayer($campaign: String!) {
    onMutatePlayer(campaign: $campaign) {
      id
      campaign
      name
      username
      lastActiveAt
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateSpace = /* GraphQL */ `
  subscription OnMutateSpace($campaign: String!) {
    onMutateSpace(campaign: $campaign) {
      id
      campaign
      name
      spaceType
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateMessage = /* GraphQL */ `
  subscription OnMutateMessage($campaign: String!) {
    onMutateMessage(campaign: $campaign) {
      id
      campaign
      messageType
      body
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateMechanic = /* GraphQL */ `
  subscription OnMutateMechanic($campaign: String!) {
    onMutateMechanic(campaign: $campaign) {
      id
      campaign
      name
      json
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateMonster = /* GraphQL */ `
  subscription OnMutateMonster($campaign: String!) {
    onMutateMonster(campaign: $campaign) {
      id
      campaign
      name
      description
      image
      strength
      dexterity
      constitution
      intelligence
      wisdom
      charisma
      size
      creatureType
      alignment
      armorBonus
      armorLabel
      hitDiceCount
      hitDiceSize
      speed
      climbSpeed
      burrowSpeed
      flySpeed
      swimSpeed
      darkvision
      blindsight
      challengeRating
      skills
      saves
      vulnerabilities
      resistances
      damageImmunities
      conditionImmunities
      languages
      traits {
        id
        trait
        name
        description
      }
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      reactions {
        id
        key
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateLanguage = /* GraphQL */ `
  subscription OnMutateLanguage($campaign: String!) {
    onMutateLanguage(campaign: $campaign) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateSkill = /* GraphQL */ `
  subscription OnMutateSkill($campaign: String!) {
    onMutateSkill(campaign: $campaign) {
      id
      campaign
      name
      ability
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateDamageType = /* GraphQL */ `
  subscription OnMutateDamageType($campaign: String!) {
    onMutateDamageType(campaign: $campaign) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateCondition = /* GraphQL */ `
  subscription OnMutateCondition($campaign: String!) {
    onMutateCondition(campaign: $campaign) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateCreatureType = /* GraphQL */ `
  subscription OnMutateCreatureType($campaign: String!) {
    onMutateCreatureType(campaign: $campaign) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateTrait = /* GraphQL */ `
  subscription OnMutateTrait($campaign: String!) {
    onMutateTrait(campaign: $campaign) {
      id
      campaign
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onMutateItem = /* GraphQL */ `
  subscription OnMutateItem($campaign: String!) {
    onMutateItem(campaign: $campaign) {
      id
      campaign
      name
      itemType
      description
      image
      armorBonus
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
