import NewCampaignPage from './pages/NewCampaignPage'
import ListCampaignsPage from './pages/ListCampaignsPage'
import ShowCampaignPage from './pages/ShowCampaignPage'
import EditCampaignPage from './pages/EditCampaignPage'
import { Switch, Route } from "react-router-dom"

export default function LoggedInRouter() {
  return (
    <Switch>
      <Route path="/campaigns/new">
        <NewCampaignPage/>
      </Route>
      <Route path="/campaigns/:campaignId/edit">
        <EditCampaignPage />
      </Route>
      <Route path="/campaigns/:campaignId">
        <ShowCampaignPage />
      </Route>
      <Route path="/">
        <ListCampaignsPage />
      </Route>
    </Switch>
  )
}
