import HiddenEditableField from '../HiddenEditableField'

export default function Reaction({reaction, setObject, editing, removeReaction}) {
  const clickRemove = (e) => {
    e.preventDefault()
    removeReaction(reaction.object)
  }
  return (<div className='reaction-block'>
    <HiddenEditableField attribute="key" setObject={setObject} editing={editing} object={reaction.object} />
    <label>{reaction.name}.</label>
    {reaction.description}
    {editing ?
      <button className='btn delete' onClick={clickRemove}>x</button>:
      null}
  </div>)
}
