const capitalize = (string) => {
  string = string || ""
  if (string === "") {
    return ""
  }
  const [firstLetter, ...restOfWord] = string
  return firstLetter.toUpperCase() + restOfWord.join('')
}


const reactionBuilders = {
  channelMagic: (monster) => {
    return {
      name: "Channel Magic",
      description: `${capitalize(monster.articulatedName)} delivers a spell you cast that has a range of touch. ${capitalize(monster.articulatedName)} must be within 120 feet of you.`
    }
  },
  deflectAttack: (monster) => {
    return {
      name: "Deflect Attack",
      description: `${capitalize(monster.articulatedName)} imposes disadvantage on the attack roll of one creature it can see that is within 5 feet of it, provided the attack roll is against a creature other than the defender. Whenever your steel defender uses its Deflect Attack, the attacker takes force damage equal to 1d4 + your Intelligence modifier.`
    }
  }
}

export default class MonsterActionDecorator {
  constructor(monsterDecorator, data) {
    this.monsterDecorator = monsterDecorator
    this.data = data
    this.data.type = 'MonsterReaction'
    const builder = reactionBuilders[this.data.key]
    if (builder) {
      const built = builder(this.monsterDecorator)
      this.data = {...built, ...this.data}
    }
}

  get object() {
    return this.data
  }

  get name() {
    return this.data.name
  }

  get description() {
    return this.data.description
  }
}
