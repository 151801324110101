import * as Schema from './FormSchema'
import { v4 as uuidv4 } from 'uuid';

export default function buildObject(type) {
  const schema = Schema[type]
  const object = {
    id: uuidv4(),
    type: type
  }
  for (const field of schema.fields) {
    if (field.hasOwnProperty('default')) {
      let value = field.default
      if (value instanceof Array) {
        value = [...value]
      }
      object[field.key] = value
    }
  }
  return object
}
