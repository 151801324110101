import { Link } from "react-router-dom"
import { useContext } from '../reactDataStore'

export default function CampaignOverview() {
  const {campaign, isOwner} = useContext()

  if (!campaign) {
    return null
  }

  return (
    <div>
      <div className="d-flex">
        <h2>{campaign.name}</h2>
        { isOwner ?
          <Link to={`/campaigns/${campaign.id}/edit`} className='btn'>✏️ Edit</Link> :
          null
        }
      </div>

      {
        campaign.imageData && <img alt={`${campaign.name} campaign`} src={campaign.imageData} />
      }

      <p>{campaign.description}</p>
    </div>
  )
}
