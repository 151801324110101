import EditableField from '../EditableField'
import ActionDamage from './ActionDamage'
import HiddenEditableField from '../HiddenEditableField'

function AttackLine({action, setObject, editing}) {
  return <span>
    :{" "}<HiddenEditableField attribute="attackAbility" object={action.object} setObject={setObject} editing={editing} />
    {action.formattedAttackBonus} to hit,{" "}
    {action.actionType === "Melee Weapon Attack" ?
      <span>
        reach <EditableField attribute="reach" setObject={setObject} editing={editing} decorator={action} /> ft.,
      </span> :
      null}
    {action.actionType === "Ranged Weapon Attack" ?
      <span>
        range <EditableField attribute="range" setObject={setObject} editing={editing} decorator={action} /> ft.,
      </span> :
      null}
    {" "}
    <EditableField attribute="target" setObject={setObject} editing={editing} decorator={action} />.
    Hit:{" "}
    <ActionDamage setObject={setObject} action={action} editing={editing} />
  </span>
}

export default function Action({action, setObject, editing, removeAction}) {
  const clickRemove = (e) => {
    e.preventDefault()
    removeAction(action.object)
  }
  const isAttack = (action.actionType === 'Melee Weapon Attack' || action.actionType === 'Ranged Weapon Attack')
  return (<div className='action-block'>
    <label>
      <EditableField attribute="name" setObject={setObject} editing={editing} decorator={action} />.
    </label>
    <EditableField attribute="actionType" setObject={setObject} editing={editing} decorator={action} />
    { isAttack ? <AttackLine action={action} setObject={setObject} editing={editing} /> : null}
    .
    {action.hitEffect && " "}
    <EditableField attribute="hitEffect" setObject={setObject} editing={editing} decorator={action} />
    {editing ?
      <button className='btn delete' onClick={clickRemove}>x</button>:
      null}
  </div>)
}
