import EditableField from '../EditableField'
import ActionDamageFormula from './ActionDamageFormula'

export default function ActionDamage({setObject, action, editing}) {
  return (<span>
    <ActionDamageFormula setObject={setObject} action={action} editing={editing} />{" "}
    <EditableField setObject={setObject} attribute="damageType" editing={editing} decorator={action} />{" "}
    damage
  </span>)
}
