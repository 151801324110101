/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0dc94cc2-c7c9-4ce2-99c8-e6045bce22c5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GbiYclkLD",
    "aws_user_pools_web_client_id": "oba0tp0i96en98fh8bdbll43f",
    "oauth": {
        "domain": "rollparadiseb67dc1e9-b67dc1e9-main.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.rollparadise.com/,http://localhost:3000/",
        "redirectSignOut": "https://www.rollparadise.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://scb7q5tp2fg75e4boln4vuyesu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "rollparadisecfb8a738a13a4eee91dad65460e78bef40310-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
