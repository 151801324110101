import { useEffect } from 'react'
import LoggedInRouter from './LoggedInRouter'
import {Context} from '../context'
import { WithAuthenticator } from './WithAuthenticator'
import LoggedInHeader from './LoggedInHeader'

function RequireLogin() {
  useEffect(() => {
    Context.start()
    return () => {Context.stop()}
  }, [])

  return (
    <div className="h-100 d-flex flex-column app-container">
      <LoggedInHeader />
      <LoggedInRouter/>
    </div>
  )
}

export default WithAuthenticator(RequireLogin)
