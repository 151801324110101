import { Backend } from '../backend'
import { useContext, useObject } from '../reactDataStore'
import { CampaignDataStore } from '../campaignDataStore'
import buildObject from './FormSchemaFactory'
import { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import LoadingIndicator from './LoadingIndicator'
import MonsterBlock from './monster/Block'
import ItemBlock from './item/Block'
import ObjectInputElement from './ObjectInputElement'
import * as Schema from "./FormSchema"

function buildFormFields(schema, object, setObject, context) {
  return schema.fields.map((field) => {
    if (!field.form) {
      return null
    }
    return(
      <div key={`${field.key}_form_field`} className="mb-3">
        <label htmlFor={`${field.key}_input`} className="form-label">{field.form.label}</label>
        <ObjectInputElement
          object={object}
          setObject={setObject}
          field={field}
        />
      </div>
    )
  })
}

function useQuery(key) {
  const params = new URLSearchParams(useLocation().search)
  return params.get(key)
}

export default function CampaignChildForm() {
  const type = useQuery("objectType")
  const { objectId } = useParams()
  const history = useHistory()
  const schema = Schema[type]
  const context = useContext()
  const rawObject = useObject('campaign', objectId)
  const [object, setObject] = useState(null)
  useEffect(() => {
    if (objectId) {
      setObject(CampaignDataStore.get(objectId))
    }
    else {
      setObject(buildObject(type))
    }
  }, [objectId, type])

  if (rawObject && !object) {
    setObject(rawObject)
  }

  if (!object) {
    return <LoadingIndicator/>
  }

  async function saveObject(event) {
    event.preventDefault()
    if (objectId) {
      await Backend.updateObject(type, object)
      history.push(`/campaigns/${context.campaign.id}/objects/${objectId}?objectType=${type}`)
    }
    else {
      const result = await Backend.createCampaignChild(type, object)
      history.push(`/campaigns/${context.campaign.id}/objects/${result.id}?objectType=${type}`)
    }
  }

  const formFields = buildFormFields(schema, object, setObject, context)
  if (type === 'Monster') {
    return (
      <div>
        <form onSubmit={saveObject}>
          <MonsterBlock object={object} setObject={setObject} />
        </form>
      </div>
    )
  }
  if (type === 'Item') {
    return (
      <div>
        <form onSubmit={saveObject}>
          <ItemBlock object={object} setObject={setObject} />
        </form>
      </div>
    )
  }
  return (
    <div>
      <h4>{objectId ? `Edit ${object.name}` : `Add ${type}`}</h4>
      <form onSubmit={saveObject}>
        {formFields}
        <button className="btn btn-primary" type="submit">
          Save
        </button>
        <Link className='ms-2' to={`/campaigns/${context.campaign.id}/objects/${objectId}?objectType=${type}`}>
          Cancel
        </Link>
      </form>
    </div>
  )
}
