import './LoggedInHeader.scss'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Dropdown } from 'react-bootstrap'
import CampaignsDropdown from './campaignsDropdown'
import { useUser } from '../reactDataStore'

export default function LoggedInHeader() {
  const user = useUser()
  // return null

  if (!user) {
    return null
  }

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <CampaignsDropdown/>
        <Dropdown as="div">
          <Dropdown.Toggle as="div">
            {user.attributes.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item><AmplifySignOut/></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  )
}
