import './LoggedInHeader.scss'
import logo from '../logo.png'
import { Dropdown } from 'react-bootstrap'
import { useHistory } from "react-router-dom"
import { useObjectsOfType, useCampaign } from '../reactDataStore'

export default function CampaignsDropdown() {
  const campaigns = useObjectsOfType('global', "Campaign")
  const campaign = useCampaign()
  const history = useHistory()

  function onClick(event) {
    event.preventDefault()
    history.push(event.target.getAttribute("href"))
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as="div">
        <img className="roll-paradise-logo" src={logo} alt="Roll Paradise logo" height="24" />
        { campaign ? campaign.name : null }
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="/" onClick={onClick}>Campaigns</Dropdown.Item>
        <hr className="dropdown-divider" />
        {campaigns.map((campaign) => {
          return <Dropdown.Item
            href={`/campaigns/${campaign.id}`}
            onClick={onClick}
            key={`nav_campaign_${campaign.id}`}>
            {campaign.name}
          </Dropdown.Item>
        })}
        <hr className="dropdown-divider" />
        <Dropdown.Item href="/campaigns/new" onClick={onClick}>
          ➕ Campaign
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
