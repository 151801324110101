import ObjectInputElement from '../ObjectInputElement'

export default function ArmorClass({monster, setObject, editing}) {
  return <div>
    <label>Armor Class</label>
    {monster.armorClass}
    {monster.armorLabel ? ` (${monster.armorLabel})` : null}
    {editing ?
      <span className="ms-5">
        Armor Value:
        <ObjectInputElement object={monster.object} setObject={setObject} attribute="armorBonus" />
        <ObjectInputElement object={monster.object} setObject={setObject} attribute="armorLabel" />
      </span> :
      null
    }
  </div>

}
