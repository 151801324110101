import './Block.scss'
import MonsterDecorator from "../../monsterDecorator"
import EditableField from '../EditableField'
import AbilityTable from './AbilityTable'
import ArmorClass from './ArmorClass'
import Sense from './Sense'
import HitPoints from './HitPoints'
import ActionsSection from './ActionsSection'
import ReactionsSection from './ReactionsSection'
import TraitsSection from './TraitsSection'
import Speeds from './Speeds'
import EditableList from '../EditableList'
import { Link } from 'react-router-dom'
import { useObjectsOfType } from '../../reactDataStore'

export default function MonsterBlock({object, setObject}) {
  const editing = !!setObject
  const availableLanguages = useObjectsOfType("campaign", "Language").map(l => l.name)
  const damageTypes = useObjectsOfType("campaign", "DamageType").map(d => d.name)
  const conditions = useObjectsOfType("campaign", "Condition").map(d => d.name)
  const skills = useObjectsOfType("campaign", "Skill")
  const traits = useObjectsOfType("campaign", "Trait")
  const creatureTypes = useObjectsOfType("campaign", "CreatureType").map(d => d.name)
  const skillNames = skills.map(s => s.name)
  const monster = new MonsterDecorator(object, {skills: skills, traits: traits})

  return (
    <div className='card stat-block m-3 p-3'>
      <section>
        <div className='d-flex'>
          <h3>
            <EditableField setObject={setObject} attribute="name" editing={editing} decorator={monster} />
          </h3>
          <div className='ms-auto'>
            {editing ?
              <span>
                <Link to={`/campaigns/${object.campaign}/objects/${object.id}?objectType=${object.type}`}>
                  Cancel
                </Link>
                <button className="btn btn-primary ms-1" type="submit">
                  Save
                </button>
              </span> :
              <Link className='ms-auto' to={`/campaigns/${object.campaign}/objects/${object.id}/edit?objectType=${object.type}`}>
                ✏️ Edit
              </Link>
            }
          </div>
        </div>
        <div>
          <EditableField setObject={setObject} attribute="size" editing={editing} decorator={monster} />{" "}
          <EditableField setObject={setObject} attribute="creatureType" editing={editing} decorator={monster} suggestions={creatureTypes} />,{" "}
          <EditableField setObject={setObject} attribute="alignment" editing={editing} decorator={monster} />
        </div>
      </section>
      <section>
        <ArmorClass monster={monster} editing={editing} setObject={setObject} />
        <HitPoints monster={monster} editing={editing} setObject={setObject} />
        <Speeds monster={monster} editing={editing} setObject={setObject} />
      </section>
      <section>
        <AbilityTable setObject={setObject} editing={editing} monster={monster} />
      </section>
      <section>
        <EditableList attribute="saves" editing={editing} decorator={monster} setObject={setObject} />
        <EditableList attribute="skills" editing={editing} decorator={monster} setObject={setObject} suggestions={skillNames} />
        <EditableList attribute="vulnerabilities" editing={editing} decorator={monster} setObject={setObject} suggestions={damageTypes} />
        <EditableList attribute="resistances" editing={editing} decorator={monster} setObject={setObject} suggestions={damageTypes} />
        <EditableList attribute="damageImmunities" editing={editing} decorator={monster} setObject={setObject} suggestions={damageTypes} />
        <EditableList attribute="conditionImmunities" editing={editing} decorator={monster} setObject={setObject} suggestions={conditions} />
        <div>
          <label>Senses</label>
          <Sense attribute="darkvision" editing={editing} monster={monster} setObject={setObject} />
          <Sense attribute="blindsight" editing={editing} monster={monster} setObject={setObject} />
          Passive Perception {monster.passivePerception}
        </div>
        <EditableList attribute="languages" setObject={setObject} editing={editing} decorator={monster} suggestions={availableLanguages} />
        <div>
          <label>Challenge</label>
          <EditableField attribute="challengeRating" setObject={setObject} editing={editing} decorator={monster} />
          {" "}({monster.experiencePoints} XP)
          <label className='ms-5'>Proficiency Bonus</label>
          {monster.signedProficiencyBonus}
        </div>
      </section>
      <TraitsSection monster={monster} setObject={setObject} editing={editing} definitions={traits} />
      <ActionsSection monster={monster} setObject={setObject} editing={editing} />
      <ReactionsSection monster={monster} setObject={setObject} editing={editing} />
    </div>
  )
}
