import Speed from './Speed'

const extraSpeeds = ["climb", "burrow", "fly", "swim"]
export default function Speeds({monster, setObject, editing}) {
  return <div>
    <label>Speed</label>
    <Speed key="speed" attribute="speed" monster={monster} setObject={setObject} editing={editing} label={false} />
    {extraSpeeds.map(s => {
      return <Speed key={`${s}Speed`} attribute={`${s}Speed`} monster={monster} setObject={setObject} editing={editing} prepend=", " />
    })}
  </div>
}
