import { Switch, Route } from "react-router-dom"
import { useEffect } from 'react'
import { useParams, useRouteMatch } from "react-router-dom"
import { useObject } from '../../reactDataStore'
import { Context } from '../../context'
import CampaignOverview from '../CampaignOverview'
import MessagePanel from '../MessagePanel'
import ActivePlayersPanel from '../ActivePlayersPanel'
import ObjectBrowser from '../ObjectBrowser'
import CampaignChildForm from '../CampaignChildForm'
import CampaignChildDetails from '../CampaignChildDetails'

export default function ShowCampaignPage(props) {
  const { campaignId } = useParams()
  const routeMatch = useRouteMatch()

  const campaign = useObject('global', campaignId)

  useEffect(() => {
    if (campaign) {
      Context.campaign = campaign
    }
    return () => {
      Context.clearCampaign()
    }
  }, [campaign])

  if (!campaign) {
    return null
  }

  return (
    <div className='flex-grow-1 campaign-page'>
      <div className='campaign-content d-flex flex-column'>
        <div>
          <Switch>
            <Route path={`${routeMatch.path}/objects/new`}>
              <CampaignChildForm/>
            </Route>
            <Route path={`${routeMatch.path}/objects/:objectId/edit`}>
              <CampaignChildForm/>
            </Route>
            <Route path={`${routeMatch.path}/objects/:objectId`}>
              <CampaignChildDetails/>
            </Route>
          </Switch>
        </div>

        <div className='mt-auto communication-tray d-flex'>
          <div className='px-3'>
            <ActivePlayersPanel/>
          </div>
          <div className='flex-grow-1 px-3'>
            <MessagePanel/>
          </div>
        </div>
      </div>
      <div className="sidebar">
        <div className="focus-panel">
          <CampaignOverview />
        </div>
        <div className="object-browser">
          <ObjectBrowser routeMatch={routeMatch} />
        </div>
      </div>
    </div>
  )
}
