import { Switch, Route, Link } from "react-router-dom"
import { useContext } from '../reactDataStore'
import CampaignChildrenTable from './CampaignChildrenTable'

export default function ObjectBrowser({routeMatch}) {
  const context = useContext()
  function typeLink(type, label) {
    return <Link key={`${type}_link`}
      className="px-1"
      to={`${routeMatch.url}/objects?objectType=${type}`}>
      {label}
    </Link>
  }
  const links = [
    typeLink("Character", "👥")
  ]
  if (context.isOwner) {
    links.push(typeLink("Player", "😀"))
    links.push(typeLink("Space", "🧭"))
    links.push(typeLink("Mechanic", "⚙️"))
    links.push(typeLink("Monster", "💀"))
    links.push(typeLink("Language", "💬"))
    links.push(typeLink("Skill", "🤸‍♀️"))
    links.push(typeLink("DamageType", "🔥"))
    links.push(typeLink("Condition", "😲"))
    links.push(typeLink("CreatureType", "👾"))
    links.push(typeLink("Trait", "👂"))
    links.push(typeLink("Item", "⚔"))
  }
  return (
    <div>
      <div className='d-flex object-browser-nav'>
        {links}
      </div>
      <div className='object-browser-content'>
        <Switch>
          <Route path={`${routeMatch.path}/objects`}>
            <CampaignChildrenTable/>
          </Route>
        </Switch>
      </div>
    </div>
  )
}
