import MonsterActionDecorator from './monsterActionDecorator'
import MonsterTraitDecorator from './monsterTraitDecorator'
import MonsterReactionDecorator from './monsterReactionDecorator'

function abilityModifier(score) {
  return Math.floor((score - 10) / 2)
}

const crMap = {
  '0': 10,
  '1/8': 25
}

const capitalize = (string) => {
  string = string || ""
  if (string === "") {
    return ""
  }
  const [firstLetter, ...restOfWord] = string
  return firstLetter.toUpperCase() + restOfWord.join('')
}

function diceExpression(diceCount, diceSize, modifier) {
  if (modifier > 0) {
    return `${diceCount}d${diceSize} + ${modifier}`
  } else if (modifier < 0) {
    return `${diceCount}d${diceSize} - ${-modifier}`
  } else {
    return `${diceCount}d${diceSize}`
  }
}

function signedValue(value) {
  if (value < 0) {
    return `${value}`
  }
  else {
    return `+${value}`
  }
}

export default class MonsterDecorator {
  constructor(data, context) {
    this.data = data
    this.allSkills = context.skills
    this.allTraits = context.traits
  }

  get object() {
    return this.data
  }

  get type() {
    return this.data.type
  }

  get name() {
    return this.data.name
  }

  get genericName() {
    const name = this.name || ""
    const words = name.split(/\s/)
    return words[words.length - 1].toLowerCase()
  }

  get articulatedName() {
    return `the ${this.genericName}`
  }

  get size() {
    return capitalize(this.data.size)
  }

  get creatureType() {
    return this.data.creatureType
  }

  get alignment() {
    return this.data.alignment
  }

  get strength() {
    return this.data.strength
  }

  get dexterity() {
    return this.data.dexterity
  }

  get constitution() {
    return this.data.constitution
  }

  get intelligence() {
    return this.data.intelligence
  }

  get wisdom() {
    return this.data.wisdom
  }

  get charisma() {
    return this.data.charisma
  }

  get strengthModifier() {
    return abilityModifier(this.strength)
  }

  get dexterityModifier() {
    return abilityModifier(this.dexterity)
  }

  get constitutionModifier() {
    return abilityModifier(this.constitution)
  }

  get intelligenceModifier() {
    return abilityModifier(this.intelligence)
  }

  get wisdomModifier() {
    return abilityModifier(this.wisdom)
  }

  get charismaModifier() {
    return abilityModifier(this.charisma)
  }

  get armorBonus() {
    return this.data.armorBonus
  }

  get armorLabel() {
    return this.data.armorLabel
  }

  get armorClass() {
    return 10 + this.dexterityModifier + this.armorBonus
  }

  get hitDiceCount() {
    return this.data.hitDiceCount
  }

  get hitDiceSize() {
    return this.data.hitDiceSize
  }

  get hitDice() {
    return diceExpression(this.hitDiceCount, this.hitDiceSize, this.constitutionModifier * this.hitDiceCount)
  }

  get hitPoints() {
    const hitDiceCount = this.hitDiceCount || 1
    const hitDiceSize = this.hitDiceSize || 1
    const avgHitDieValue = (1 + hitDiceSize) / 2
    return Math.floor((avgHitDieValue + this.constitutionModifier) * hitDiceCount)
  }

  get passivePerception() {
    return 10 + this.skillModifier("Perception")
  }

  get challengeRating() {
    return this.data.challengeRating
  }

  get experiencePoints() {
    return crMap[this.challengeRating]
  }

  skillDefinition(name) {
    return this.allSkills.find(s => s.name === name)
  }

  get proficiencyBonus() {
    return 2
  }

  get signedProficiencyBonus() {
    return signedValue(this.proficiencyBonus)
  }

  get traits() {
    return (this.data.traits || []).map(trait => new MonsterTraitDecorator(this, trait, this.allTraits))
    // return (this.data.traits || []).map((trait) => {
    //   if (traitBuilders[trait.key]) {
    //     return traitBuilders[trait.key](this)
    //   }
    //   return null
    // }).filter(x => x)
  }

  get speed() {
    return this.data.speed
  }

  get climbSpeed() {
    return this.data.climbSpeed
  }

  get burrowSpeed() {
    return this.data.burrowSpeed
  }

  get flySpeed() {
    return this.data.flySpeed
  }

  get swimSpeed() {
    return this.data.swimSpeed
  }

  get speedInfo() {
    const fragments = []
    fragments.push(`${this.speed} ft.`)
    if (this.climbSpeed) {
      fragments.push(`climb ${this.climbSpeed} ft.`)
    }
    if (this.burrowSpeed) {
      fragments.push(`burrow ${this.burrowSpeed} ft.`)
    }
    if (this.flySpeed) {
      fragments.push(`fly ${this.flySpeed} ft.`)
    }
    if (this.swimSpeed) {
      fragments.push(`swim ${this.swimSpeed} ft.`)
    }
    return fragments.join(", ")
  }

  formattedScoreAndModifier(ability) {
    const score = this[ability]
    const modifier = this[`${ability}Modifier`]
    return `${score} (${signedValue(modifier)})`
  }

  abilityModifier(ability) {
    return this[`${ability}Modifier`]
  }

  formattedModifier(ability) {
    const modifier = this[`${ability}Modifier`]
    return ` (${signedValue(modifier)})`
  }

  get actions() {
    return (this.data.actions || []).map(action => new MonsterActionDecorator(this, action))
  }

  get reactions() {
    return (this.data.reactions || []).map(reaction => new MonsterReactionDecorator(this, reaction))
  }

  get languages() {
    if (this.data.languages && this.data.languages.length > 0) {
      return this.data.languages.join(", ")
    }
    else {
      return '--'
    }
  }

  get vulnerabilities() {
    return capitalize((this.data.vulnerabilities || []).join(", "))
  }

  get skills() {
    return (this.data.skills || []).map(s => {
      const modifier = this.skillModifier(s)
      return `${capitalize(s)} ${signedValue(modifier)}`
    }).join(", ")
  }

  get saves() {
    return (this.data.saves || []).map(s => {
      const modifier = this.abilityModifier(s) + this.proficiencyBonus
      return `${s.substring(0, 3).toUpperCase()} ${signedValue(modifier)}`
    }).join(", ")
  }

  proficientInSkill(skill) {
    return (this.data.skills || []).includes(skill)
  }

  skillModifier(skill) {
    const definition = this.skillDefinition(skill)
    if (!definition) {
      return 0
    }
    const abilityModifier = this.abilityModifier(definition.ability)
    if (this.proficientInSkill(skill)) {
      return abilityModifier + this.proficiencyBonus
    }
    else {
      return abilityModifier
    }
  }

  get resistances() {
    return capitalize((this.data.resistances || []).join(", "))
  }

  get damageImmunities() {
    return capitalize((this.data.damageImmunities || []).join(", "))
  }

  get conditionImmunities() {
    return capitalize((this.data.conditionImmunities || []).join(", "))
  }

  get darkvision() {
    return this.data.darkvision
  }

  get blindsight() {
    return this.data.blindsight
  }
}
