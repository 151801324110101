import Trait from './Trait'
import buildObject from "../FormSchemaFactory"

export default function TraitsSection({monster, setObject, editing, definitions}) {
  const addTrait = (e) => {
    e.preventDefault()
    const traits = monster.object.traits || []
    traits.push(buildObject('MonsterTrait'))
    setObject({...monster.object, traits})
  }

  const setTrait = (trait) => {
    const traits = monster.object.traits || []
    const index = traits.findIndex(item => item.id === trait.id)
    if (index >= 0) {
      traits[index] = trait
    }
    else {
      traits.push(trait)
    }
    setObject({...monster.object, traits})
  }

  const removeTrait = (trait) => {
    let traits = monster.object.traits || []
    traits = traits.filter(item => item.id !== trait.id)
    setObject({...monster.object, traits})
  }

  if (editing || monster.traits.length > 0) {
    return <section>
      <div className='d-flex'>
        {editing ?
          <button className='btn ms-auto' onClick={addTrait}>➕</button> :
          null
        }
      </div>
      {
        monster.traits.map((trait, index) => {
          return <Trait key={`trait_${index}`} trait={trait} setObject={setTrait} editing={editing} removeTrait={removeTrait} definitions={definitions} />
        })
      }
    </section>
  }
  else {
    return null
  }
}
