/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncCampaigns = /* GraphQL */ `
  query SyncCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        image
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      description
      image
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCharacters = /* GraphQL */ `
  query SyncCharacters(
    $filter: ModelCharacterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCharacters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        characterType
        attributes
        portrait
        token
        silhouette
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCharacter = /* GraphQL */ `
  query GetCharacter($id: ID!) {
    getCharacter(id: $id) {
      id
      campaign
      name
      characterType
      attributes
      portrait
      token
      silhouette
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCharacters = /* GraphQL */ `
  query ListCharacters(
    $filter: ModelCharacterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharacters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        characterType
        attributes
        portrait
        token
        silhouette
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlayers = /* GraphQL */ `
  query SyncPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlayers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        username
        lastActiveAt
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      campaign
      name
      username
      lastActiveAt
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        username
        lastActiveAt
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpaces = /* GraphQL */ `
  query SyncSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        spaceType
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      campaign
      name
      spaceType
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        spaceType
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        messageType
        body
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      campaign
      messageType
      body
      members
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        messageType
        body
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMechanics = /* GraphQL */ `
  query SyncMechanics(
    $filter: ModelMechanicFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMechanics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        json
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMechanic = /* GraphQL */ `
  query GetMechanic($id: ID!) {
    getMechanic(id: $id) {
      id
      campaign
      name
      json
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMechanics = /* GraphQL */ `
  query ListMechanics(
    $filter: ModelMechanicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMechanics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        json
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDamageTypes = /* GraphQL */ `
  query SyncDamageTypes(
    $filter: ModelDamageTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDamageTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDamageType = /* GraphQL */ `
  query GetDamageType($id: ID!) {
    getDamageType(id: $id) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDamageTypes = /* GraphQL */ `
  query ListDamageTypes(
    $filter: ModelDamageTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDamageTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCreatureTypes = /* GraphQL */ `
  query SyncCreatureTypes(
    $filter: ModelCreatureTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCreatureTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCreatureType = /* GraphQL */ `
  query GetCreatureType($id: ID!) {
    getCreatureType(id: $id) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCreatureTypes = /* GraphQL */ `
  query ListCreatureTypes(
    $filter: ModelCreatureTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatureTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConditions = /* GraphQL */ `
  query SyncConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCondition = /* GraphQL */ `
  query GetCondition($id: ID!) {
    getCondition(id: $id) {
      id
      campaign
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listConditions = /* GraphQL */ `
  query ListConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSkills = /* GraphQL */ `
  query SyncSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSkills(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        ability
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      campaign
      name
      ability
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        ability
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTraits = /* GraphQL */ `
  query SyncTraits(
    $filter: ModelTraitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTraits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrait = /* GraphQL */ `
  query GetTrait($id: ID!) {
    getTrait(id: $id) {
      id
      campaign
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTraits = /* GraphQL */ `
  query ListTraits(
    $filter: ModelTraitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMonsters = /* GraphQL */ `
  query SyncMonsters(
    $filter: ModelMonsterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMonsters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        description
        image
        strength
        dexterity
        constitution
        intelligence
        wisdom
        charisma
        size
        creatureType
        alignment
        armorBonus
        armorLabel
        hitDiceCount
        hitDiceSize
        speed
        climbSpeed
        burrowSpeed
        flySpeed
        swimSpeed
        darkvision
        blindsight
        challengeRating
        skills
        saves
        vulnerabilities
        resistances
        damageImmunities
        conditionImmunities
        languages
        traits {
          id
          trait
          name
          description
        }
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        reactions {
          id
          key
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMonster = /* GraphQL */ `
  query GetMonster($id: ID!) {
    getMonster(id: $id) {
      id
      campaign
      name
      description
      image
      strength
      dexterity
      constitution
      intelligence
      wisdom
      charisma
      size
      creatureType
      alignment
      armorBonus
      armorLabel
      hitDiceCount
      hitDiceSize
      speed
      climbSpeed
      burrowSpeed
      flySpeed
      swimSpeed
      darkvision
      blindsight
      challengeRating
      skills
      saves
      vulnerabilities
      resistances
      damageImmunities
      conditionImmunities
      languages
      traits {
        id
        trait
        name
        description
      }
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      reactions {
        id
        key
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMonsters = /* GraphQL */ `
  query ListMonsters(
    $filter: ModelMonsterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonsters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        description
        image
        strength
        dexterity
        constitution
        intelligence
        wisdom
        charisma
        size
        creatureType
        alignment
        armorBonus
        armorLabel
        hitDiceCount
        hitDiceSize
        speed
        climbSpeed
        burrowSpeed
        flySpeed
        swimSpeed
        darkvision
        blindsight
        challengeRating
        skills
        saves
        vulnerabilities
        resistances
        damageImmunities
        conditionImmunities
        languages
        traits {
          id
          trait
          name
          description
        }
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        reactions {
          id
          key
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncItems = /* GraphQL */ `
  query SyncItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        campaign
        name
        itemType
        description
        image
        armorBonus
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      campaign
      name
      itemType
      description
      image
      armorBonus
      actions {
        id
        name
        actionType
        attackAbility
        reach
        range
        target
        twoHanded
        damageDiceCount
        damageDiceSize
        damageType
        hitEffect
      }
      members
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaign
        name
        itemType
        description
        image
        armorBonus
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const charactersByCampaign = /* GraphQL */ `
  query CharactersByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCharacterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    charactersByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        characterType
        attributes
        portrait
        token
        silhouette
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const playersByCampaign = /* GraphQL */ `
  query PlayersByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playersByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        username
        lastActiveAt
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const spacesByCampaign = /* GraphQL */ `
  query SpacesByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        spaceType
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const messagesByCampaign = /* GraphQL */ `
  query MessagesByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        messageType
        body
        members
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const mechanicsByCampaign = /* GraphQL */ `
  query MechanicsByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMechanicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mechanicsByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        json
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languagesByCampaign = /* GraphQL */ `
  query LanguagesByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    languagesByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const damageTypesByCampaign = /* GraphQL */ `
  query DamageTypesByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDamageTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    damageTypesByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const creatureTypesByCampaign = /* GraphQL */ `
  query CreatureTypesByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCreatureTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creatureTypesByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const conditionsByCampaign = /* GraphQL */ `
  query ConditionsByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conditionsByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const skillsByCampaign = /* GraphQL */ `
  query SkillsByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillsByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        ability
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const traitsByCampaign = /* GraphQL */ `
  query TraitsByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTraitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traitsByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const monstersByCampaign = /* GraphQL */ `
  query MonstersByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMonsterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    monstersByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        description
        image
        strength
        dexterity
        constitution
        intelligence
        wisdom
        charisma
        size
        creatureType
        alignment
        armorBonus
        armorLabel
        hitDiceCount
        hitDiceSize
        speed
        climbSpeed
        burrowSpeed
        flySpeed
        swimSpeed
        darkvision
        blindsight
        challengeRating
        skills
        saves
        vulnerabilities
        resistances
        damageImmunities
        conditionImmunities
        languages
        traits {
          id
          trait
          name
          description
        }
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        reactions {
          id
          key
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const itemsByCampaign = /* GraphQL */ `
  query ItemsByCampaign(
    $campaign: ID
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCampaign(
      campaign: $campaign
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaign
        name
        itemType
        description
        image
        armorBonus
        actions {
          id
          name
          actionType
          attackAbility
          reach
          range
          target
          twoHanded
          damageDiceCount
          damageDiceSize
          damageType
          hitEffect
        }
        members
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
