import EditableField from '../EditableField'

export default function AbilityTable({monster, setObject, editing}) {
  return <table>
    <thead>
      <tr>
        <th>STR</th>
        <th>DEX</th>
        <th>CON</th>
        <th>INT</th>
        <th>WIS</th>
        <th>CHA</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <EditableField setObject={setObject} attribute="strength" editing={editing} decorator={monster} />
          {monster.formattedModifier("strength")}
        </td>
        <td>
          <EditableField setObject={setObject} attribute="dexterity" editing={editing} decorator={monster} />
          {monster.formattedModifier("dexterity")}
        </td>
        <td>
          <EditableField setObject={setObject} attribute="constitution" editing={editing} decorator={monster} />
          {monster.formattedModifier("constitution")}
        </td>
        <td>
          <EditableField setObject={setObject} attribute="intelligence" editing={editing} decorator={monster} />
          {monster.formattedModifier("intelligence")}
        </td>
        <td>
          <EditableField setObject={setObject} attribute="wisdom" editing={editing} decorator={monster} />
          {monster.formattedModifier("wisdom")}
        </td>
        <td>
          <EditableField setObject={setObject} attribute="charisma" editing={editing} decorator={monster} />
          {monster.formattedModifier("charisma")}
        </td>
      </tr>
    </tbody>
  </table>
}
