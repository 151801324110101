import React, {
  ComponentType,
  FunctionComponent,
} from 'react';
import { Auth, appendToCognitoUserAgent } from '@aws-amplify/auth';
import {
  AmplifyContainer,
  AmplifyAuthenticator,
  AmplifyAuthContainer,
  AmplifySignUp,
  AmplifySignIn
} from '@aws-amplify/ui-react';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { Logger } from '@aws-amplify/core';

const logger = new Logger('WithAuthenticator');

export function WithAuthenticator<T extends object>(
  Component: ComponentType<T>
) {
  const AppWithAuthenticator: FunctionComponent<T> = props => {
    const [signedIn, setSignedIn] = React.useState(false);

    React.useEffect(() => {
      function checkUser() {
        setUser();

        return onAuthUIStateChange(authState => {
          if (authState === AuthState.SignedIn) {
            setSignedIn(true);
          } else if (authState === AuthState.SignedOut) {
            setSignedIn(false);
          }
        });
      }

      appendToCognitoUserAgent('WithAuthenticator');

      // checkUser returns an "unsubscribe" function to stop side-effects
      return checkUser();
    }, []);

    async function setUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) setSignedIn(true);
      } catch (err) {
        logger.debug(err);
      }
    };

    if (!signedIn) {
      return (
        <AmplifyContainer>
          <AmplifyAuthContainer>
            <AmplifyAuthenticator {...props} >
                <div slot="sign-in">
                  <AmplifySignIn>
                    <div slot="federated-buttons" />
                  </AmplifySignIn>
                </div>
                <AmplifySignUp
                  usernameAlias='email'
                  slot="sign-up"
                  formFields={[
                    { type: "email", required: true },
                    { type: "password", required: true },
                    { type: "name", label: "Name *", required: true }
                  ]}
                />
              </AmplifyAuthenticator>
          </AmplifyAuthContainer>
        </AmplifyContainer>
      );
    }

    return <Component {...props} />;
  };

  return AppWithAuthenticator;
}
