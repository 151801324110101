import EditableField from '../EditableField'
import { Link } from 'react-router-dom'
import ItemDecorator from "../../itemDecorator"

export default function ItemBlock({object, setObject}) {
  const editing = !!setObject
  const item = new ItemDecorator(object)

  return (
    <div className='card stat-block m-3 p-3'>
      <section>
        <div className='d-flex'>
          <h3>
            <EditableField setObject={setObject} attribute="name" editing={editing} decorator={item} />
          </h3>
          <div className='ms-auto'>
            {editing ?
              <span>
                <Link to={`/campaigns/${object.campaign}/objects/${object.id}?objectType=${object.type}`}>
                  Cancel
                </Link>
                <button className="btn btn-primary ms-1" type="submit">
                  Save
                </button>
              </span> :
              <Link className='ms-auto' to={`/campaigns/${object.campaign}/objects/${object.id}/edit?objectType=${object.type}`}>
                ✏️ Edit
              </Link>
            }
          </div>
        </div>
        <div>
          <EditableField setObject={setObject} attribute="itemType" editing={editing} decorator={item} />{" "}
        </div>
      </section>
    </div>
  )
}
