import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom"
import { Backend } from '../../backend'

export default function NewCampaignPage(props) {
  const history = useHistory()
  const [formData, setFormData] = useState({name: null, description: null})

  const handleSubmit = async (event) => {
    event.preventDefault()
    const campaign = await Backend.createCampaign(formData)
    history.push(`/campaigns/${campaign.id}`)
  }

  return (
    <div className="NewCampaignPage">
      <h2>New Campaign</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="newCampaignName">
          <Form.Label>Name *</Form.Label>
          <Form.Control type="text" onChange={e => setFormData({ ...formData, 'name': e.target.value})} />
        </Form.Group>

        <Form.Group controlId="newCampaignDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" type="text" onChange={e => setFormData({ ...formData, 'description': e.target.value})} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </div>
  )
}
