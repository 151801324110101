import { Backend } from '../backend'
import { Link } from 'react-router-dom'

function deleteObject(type, object) {
  return async () => {
    await Backend.deleteObject(type, object)
  }
}

function buildTableCells(schema, object, context, type) {
  return schema.fields.map((field) => {
    if (field.table) {
      if (field.privacy === 'owner' && !context.isOwner) {
        return null
      }
      let content = object[field.key]
      if (typeof field.table === 'function') {
        content = field.table(content)
      }
      return <td key={`${field.key}_cell`}>
        <Link
          to={`/campaigns/${context.campaign.id}/objects/${object.id}?objectType=${type}`}>
          {content}
        </Link>
      </td>
    }
    return null
  })
}

export default function CampaignChildRow({type, object, context, schema}) {
  let tableCells = buildTableCells(schema, object, context, type)
  if (context.isOwner) {
    tableCells = tableCells.concat([
      <td key="edit_cell">
        <Link
          to={`/campaigns/${context.campaign.id}/objects/${object.id}/edit?objectType=${type}`}
          className='btn'>
          ✏️
        </Link>
      </td>,
      <td key="delete_cell">
        <button
          onClick={deleteObject(type, object)}
          className="btn btn-sm btn-danger">
          ⌫
        </button>
      </td>
    ])
  }
  return (
    <tr>
      {tableCells}
    </tr>
  )
}
