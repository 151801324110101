import RollCommand from './commands/rollCommand'
import {reportError} from './errorReporter'

const commands = {
  roll: RollCommand
}

export function execute(command, input) {
  const commandObject = commands[command]
  if (!commandObject) {
    reportError(`unexpected command ${command}. Options are ${Object.keys(commands).join(", ")}`)
    return
  }
  commandObject.execute(input)
}
