import RequireLogin from './RequireLogin'
import PrivacyPolicyContent from './PrivacyPolicyContent'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <PrivacyPolicyContent />
        </Route>
        <Route path="/">
          <RequireLogin />
        </Route>
      </Switch>
    </Router>
  )
}
