import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'
import * as subscriptions from './graphql/subscriptions';
import { API, Storage, graphqlOperation } from 'aws-amplify'
import { Context } from './context'
import { CampaignDataStore } from './campaignDataStore'

const types = {
  Campaign: {
    fields: ["name", "description"],
    images: ["image"]
  },
  Character: {
    fields: ["name", "campaign"],
    images: ["portrait", "token", "silhouette"]
  },
  Player: {
    fields: ["name", "campaign", "username", "lastActiveAt"]
  },
  Space: {
    fields: ["name", "spaceType", "campaign"]
  },
  Message: {
    fields: ["body", "messageType", "campaign"]
  },
  Mechanic: {
    fields: ["json", "name", "campaign"]
  },
  Language: {
    fields: ["name", "campaign"]
  },
  Item: {
    fields: ["name", "campaign", "description", "image", "armorBonus", "itemType"],
    nestedArrays: {
      actions: "Action"
    }
  },
  DamageType: {
    fields: ["name", "campaign"]
  },
  Trait: {
    fields: ["name", "campaign", "description"]
  },
  CreatureType: {
    fields: ["name", "campaign"]
  },
  Condition: {
    fields: ["name", "campaign"]
  },
  Skill: {
    fields: ["name", "campaign", "ability"]
  },
  Monster: {
    fields: [
      "name",
      "campaign",
      "strength",
      "dexterity",
      "constitution",
      "intelligence",
      "wisdom",
      "charisma",
      "size",
      "creatureType",
      "alignment",
      "armorBonus",
      "armorLabel",
      "hitDiceCount",
      "hitDiceSize",
      "speed",
      "climbSpeed",
      "burrowSpeed",
      "flySpeed",
      "swimSpeed",
      "darkvision",
      "blindsight",
      "challengeRating",
      "vulnerabilities",
      "resistances",
      "damageImmunities",
      "conditionImmunities",
      "languages",
      "skills",
      "saves"
    ],
    nestedArrays: {
      traits: "MonsterTrait",
      actions: "Action",
      reactions: "MonsterReaction"
    }
  },
  MonsterTrait: {
    fields: [
      "name",
      "description",
      "key",
      "trait"
    ]
  },
  Action: {
    fields: [
      "name",
      "actionType",
      "attackAbility",
      "reach",
      "range",
      "target",
      "damageDiceCount",
      "damageDiceSize",
      "damageType",
      "hitEffect"
    ]
  },
  MonsterReaction: {
    fields: [
      "key"
    ]
  }
}

for (const type of Object.values(types)) {
  type.fields.push("members")
  type.fields.push("editors")
  type.images?.forEach((image) => {
    type.fields.push(image)
  })
}

function permittedObject(object, allowedAttributes, nestedArrays) {
  const result = Object.keys(object)
    .filter(key => allowedAttributes.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {})

  for (const [attribute, type] of Object.entries(nestedArrays || {})) {
    result[attribute] = (object[attribute] || []).map(v => {
      return permittedObject(v, types[type].fields.concat("id"), types[type].nestedArrays)
    })
  }
  return result
}

class _Backend {
  async createObject(type, object) {
    object = permittedObject(object, types[type].fields, types[type].nestedArrays)
    const response = await API.graphql({
      query: mutations[`create${type}`],
      variables: {
        input: object
      }
    })
    return response.data[`create${type}`]
  }

  async createCampaignChild(type, object) {
    object.campaign = Context.campaignId
    const result = await this.createObject(type, object)
    if (this[`afterCreate${type}`]) {
      this[`afterCreate${type}`](object)
    }
    return result
  }

  async updateObject(type, object) {
    object = permittedObject(object, types[type].fields.concat(["id", "_version"]), types[type].nestedArrays)
    const response = await API.graphql({
      query: mutations[`update${type}`],
      variables: {
        input: object
      }
    })
    return response.data[`update${type}`]
  }

  async deleteObject(type, object) {
    const {id, _version} = object
    await API.graphql({
      query: mutations[`delete${type}`],
      variables: {
        input: { id, _version }
      }
    })
    if (this[`afterDelete${type}`]) {
      this[`afterDelete${type}`](object)
    }
  }

  async listObjects(type) {
    const response = await API.graphql({
      query: queries[`list${type}s`]
    })
    const items = response.data[`list${type}s`].items
    if (types[type].images) {
      const promises = []
      for (const item of items) {
        for (const field of types[type].images) {
          if (item[field]) {
            promises.push(
              Storage.get(item[field]).then(
                (image) => {
                item[`${field}Data`] = image
              })
            )
          }
        }
      }
      await Promise.all(promises)
    }
    return items
  }

  async listCampaignChildren(type) {
    const { campaignId } = Context
    const response = await API.graphql({
      query: queries[`list${type}s`],
      variables: {filter: {
        campaign: {eq: campaignId}
      }}
    })
    return response.data[`list${type}s`].items
  }

  async createCampaign(campaign) {
    return await this.createObject("Campaign", campaign)
  }

  async updateCampaign(campaign) {
    return await this.updateObject("Campaign", campaign)
  }

  async deleteCampaign(campaign) {
    await this.deleteObject("Campaign", campaign)
  }

  async listCampaigns() {
    return await this.listObjects("Campaign")
  }

  async afterCreatePlayer(player) {
    const campaign = Context.campaign
    if (!campaign.members) {
      campaign.members = []
    }
    if (!campaign.members.includes(player.username)) {
      campaign.members.push(player.username)
      await this.updateCampaign(campaign)
    }
  }

  async afterDeletePlayer(player) {
    const campaign = Context.campaign
    campaign.members = campaign.members.filter(item => item !== player.username)
    await this.updateCampaign(campaign)
  }

  async markPlayerActive() {
    const players = CampaignDataStore.objectsByType("Player")
    const player = players.find(player => player.username === Context.user.username)
    if (player) {
      player.lastActiveAt = new Date()
      await this.updateObject("Player", player)
    }
  }

  async subscribe(type, eventType, params, callback) {
    return API.graphql(
      graphqlOperation(
        subscriptions[eventType],
        params
      )
    ).subscribe({
      next: ({ provider, value }) => {
        const object = value.data[eventType]
        const promises = []
        for (const field of types[type].images || []) {
          if (object[field]) {
            promises.push(
              Storage.get(object[field]).then(
                (image) => {
                object[`${field}Data`] = image
              })
            )
          }
        }

        Promise.all(promises).then(() => {callback(object)})
      },
      error: error => console.warn(error)
    });
  }
}

export const Backend = new _Backend()
