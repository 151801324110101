import { v4 as uuidv4 } from 'uuid';
import { CampaignDataStore } from './campaignDataStore';
import { Context } from './context';

export function reportError(message) {
  CampaignDataStore.set({
    type: 'Message',
    messageType: 'ErrorMessage',
    body: message,
    createdAt: new Date().toString(),
    members: [Context.user.username],
    editors: [Context.user.username],
    id: uuidv4()
  })
}
