export default class MonsterActionDecorator {
  constructor(monsterDecorator, data) {
    this.monsterDecorator = monsterDecorator
    this.data = data
    this.data.type = 'Action'
  }

  get object() {
    return this.data
  }
  get name() {
    return this.data.name
  }
  get actionType() {
    return this.data.actionType
  }
  get attackBonus() {
    return this.monsterDecorator.proficiencyBonus + this.abilityModifier
  }
  get formattedAttackBonus() {
    const attackBonus = this.attackBonus
    if (attackBonus < 0) {
      return "+0"
    }
    else {
      return `+${attackBonus}`
    }
  }
  get reach() {
    return this.data.reach
  }
  get target() {
    return this.data.target
  }
  get damageDiceCount() {
    return this.data.damageDiceCount
  }
  get damageDiceSize() {
    return parseInt(this.data.damageDiceSize, 10)
  }
  get abilityModifier() {
    const modifierAttribute = `${this.data.attackAbility}Modifier`
    if (this.data.attackAbility && this.monsterDecorator[modifierAttribute]) {
      return this.monsterDecorator[modifierAttribute]
    }
    else {
      return 0
    }
  }
  get damageModifier() {
    return this.abilityModifier
  }
  get damageType() {
    return this.data.damageType
  }
  get averageDamage() {
    const avgDieValue = (1 + this.damageDiceSize) / 2
    const result = Math.floor(avgDieValue * this.damageDiceCount + this.damageModifier)
    return result
  }
  get hitEffect() {
    return this.data.hitEffect
  }
  get range() {
    return this.data.range
  }
}
