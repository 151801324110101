import { useState } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { Storage } from 'aws-amplify'
import Form from 'react-bootstrap/Form'
import { GlobalDataStore } from '../../globalDataStore'
import LoadingIndicator from '../LoadingIndicator'
import { Backend } from '../../backend'

export default function EditCampaignPage(props) {
  const history = useHistory()
  const { campaignId } = useParams()
  const [formData, setFormData] = useState(GlobalDataStore.get(campaignId))

  const handleSubmit = async (event) => {
    event.preventDefault()
    await Backend.updateCampaign(formData)
    history.push(`/campaigns/${campaignId}`)
  }

  async function deleteCampaign(event) {
    event.preventDefault()
    const result = window.confirm(`Are you sure you want to delete the ${formData.name} campaign? This cannot be undone.`)
    if (result) {
      await Backend.deleteCampaign(formData)
      history.push(`/campaigns/`)
    }
  }

  if (!formData) {
    return <LoadingIndicator />
  }

  async function onImageChange(e) {
    if (!e.target.files[0]) {
      setFormData({ ...formData, image: "" });
      return
    }
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);

  }

  return (
    <div>
      <h2>Edit {formData.name}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="editCampaignName">
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            onChange={e => setFormData({ ...formData, 'name': e.target.value})}
            value={formData.name}
          />
        </Form.Group>

        <Form.Group controlId="editCampaignDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            onChange={e => setFormData({ ...formData, 'description': e.target.value})}
            value={formData.description || ""}
          />
        </Form.Group>
        <Form.Group controlId="editCampaignImage">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type="file"
            onChange={onImageChange}
          />
        </Form.Group>
        <button className='btn btn-primary' type="submit">
          Save
        </button>
      </Form>
      <div className='mt-5'>
      <button className='btn btn-danger' onClick={deleteCampaign}>⌫ Delete Campaign</button>
      </div>
    </div>
  )
}
