import * as Schema from "./FormSchema"
import TagField from "./TagField"

export default function ObjectInputElement({object, setObject, field, attribute, suggestions, options, className, placeholder}) {
  if (className) {
    className = `form-control ${className}`
  }
  else {
    className = 'form-control'
  }
  if (!field) {
    const schema = Schema[object.type]
    field = schema.fields.find((field) => field.key === attribute)
  }
  if (!field.form) {
    return null
  }
  const onChange = (e) => {
    const newObject = { ...object }
    let value = e.target.value
    if (field.fromForm) {
      value = field.fromForm(value)
    }
    if (field.form.type === "integer") {
      value = parseInt(value, 10) || null
    }
    newObject[field.key] = value
    setObject(newObject)
  }
  const onChangeTags = (value) => {
    const newObject = { ...object }
    if (field.fromForm) {
      value = field.fromForm(value)
    }
    if (field.form.type === "integer") {
      value = parseInt(value, 10)
    }
    newObject[field.key] = value
    setObject(newObject)
  }
  suggestions = suggestions || field.form.suggestions
  options = options || field.form.options || []
  options = options.map(o => {
    if (o instanceof String) {
      return {id: o, name: o}
    }
    else {
      return o
    }
  })
  options.unshift({id: "", name: ""})
  let value = object[field.key]
  if (field.toForm) {
    value = field.toForm(value)
  }
  if (value === undefined || value === null) {
    value = ""
  }
  if (field.form.type === 'textarea') {
    return <textarea
      className={className}
      id={`${field.key}_input`}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  }
  else if (field.form.type === 'select') {
    return <select
      className={className}
      id={`${field.key}_input`}
      onChange={onChange}
      value={value}
    >
      {options.map(option => {
        return <option key={option.id} value={option.id}>{option.name}</option>
      })
      }
    </select>
  }
  else if (field.form.type === 'tags') {
    return <TagField
      id={`${field.key}_input`}
      onChange={onChangeTags}
      values={value}
      suggestions={suggestions}
    />
  }
  else if (suggestions) {
    return <span>
      <input
        type={field.form.type}
        className={className}
        id={`${field.key}_input`}
        onChange={onChange}
        value={value}
        list={`${field.key}-suggestions`}
        placeholder={placeholder}
      />
      <datalist id={`${field.key}-suggestions`}>
        {suggestions.map(s => <option key={s}>{s}</option>)}
      </datalist>
    </span>
  }
  else {
    return <input
      type={field.form.type}
      className={className}
      id={`${field.key}_input`}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  }
}
