function isRecent(dateString) {
  if (!dateString) {
    return false
  }

  const epoch = Date.parse(dateString) + 65000
  const date = new Date(epoch)
  return date >= new Date()
}

const abilities = [
  "strength",
  "dexterity",
  "constitution",
  "intelligence",
  "wisdom",
  "charisma"
]

export const Player = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "lastActiveAt",
      table: (value) => {return isRecent(value) ? <em>Online</em> : null},
      default: null
    },
    {
      key: "username",
      form: {
        type: "text",
        label: "Membership Code"
      },
      table: true,
      privacy: "owner"
    },
    {
      key: "members",
      default: ["*"]
    }
  ]
}

export const Space = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "spaceType",
      form: {
        type: "text",
        label: "Type"
      },
      table: true,
    }
  ]
}

export const Character = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    }
  ]
}

export const Mechanic = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "json",
      form: {
        type: "textarea",
        label: "JSON"
      }
    },
    {
      key: "members",
      default: ["*"]
    }
  ]
}

export const Language = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    }
  ]
}

export const DamageType = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    }
  ]
}

export const Trait = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "description",
      form: {
        type: "textarea",
        label: "Description"
      }
    }
  ]
}

export const CreatureType = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    }
  ]
}

export const Condition = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    }
  ]
}

export const Skill = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "ability",
      form: {
        type: "text",
        label: "Ability",
        suggestions: abilities
      },
      table: true
    }
  ]
}

export const Monster = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "size",
      default: "medium",
      form: {
        type: "text",
        suggestions: [
          "tiny",
          "small",
          "medium",
          "large",
          "huge",
          "gargantuan"
        ],
        label: "Size"
      }
    },
    {
      key: "creatureType",
      default: "humanoid",
      form: {
        type: "text",
        label: "Creature Type",
      }
    },
    {
      key: "alignment",
      default: "unaligned",
      form: {
        type: "text",
        label: "Alignment",
        suggestions: [
          "unaligned",
          "any alignment",
          "lawful good",
          "neutral good",
          "chaotic good",
          "lawful neutral",
          "neutral",
          "chaotic neutral",
          "lawful evil",
          "neutral evil",
          "chaotic evil"
        ],
      }
    },
    {
      key: "strength",
      default: 10,
      form: {
        type: "integer",
        label: "Strength"
      }
    },
    {
      key: "dexterity",
      default: 10,
      form: {
        type: "integer",
        label: "Dexterity"
      }
    },
    {
      key: "constitution",
      default: 10,
      form: {
        type: "integer",
        label: "Constitution"
      }
    },
    {
      key: "intelligence",
      default: 10,
      form: {
        type: "integer",
        label: "Intelligence"
      }
    },
    {
      key: "wisdom",
      default: 10,
      form: {
        type: "integer",
        label: "Wisdom"
      }
    },
    {
      key: "charisma",
      default: 10,
      form: {
        type: "integer",
        label: "Charisma"
      }
    },
    {
      key: "armorBonus",
      default: 0,
      form: {
        type: "integer",
        label: "Armor Bonus"
      }
    },
    {
      key: "armorLabel",
      form: {
        type: "text",
        label: "Armor Label",
        suggestions: ["Natural Armor", "Leather Armor", "Chain Shirt", "Shield"]
      }
    },
    {
      key: "hitDiceCount",
      form: {
        type: "integer",
        label: "Hit Dice Count"
      }
    },
    {
      key: "hitDiceSize",
      form: {
        type: "integer",
        label: "Hit Dice Size"
      }
    },
    {
      key: "speed",
      default: 30,
      form: {
        type: "integer",
        label: "Speed"
      }
    },
    {
      key: "climbSpeed",
      form: {
        type: "integer",
        label: "Climb"
      },
      default: 0
    },
    {
      key: "burrowSpeed",
      form: {
        type: "integer",
        label: "Burrow"
      },
      default: 0
    },
    {
      key: "flySpeed",
      form: {
        type: "integer",
        label: "Fly"
      },
      default: 0
    },
    {
      key: "swimSpeed",
      form: {
        type: "integer",
        label: "Swim"
      },
      default: 0
    },
    {
      key: "darkvision",
      form: {
        type: "integer",
        label: "Darkvision"
      },
      default: 0
    },
    {
      key: "blindsight",
      form: {
        type: "integer",
        label: "Blindsight"
      },
      default: 0
    },
    {
      key: "challengeRating",
      default: "0",
      form: {
        type: "text",
        label: "Challenge Rating"
      }
    },
    {
      key: "traits",
      default: [],
      form: {
        type: "text",
        label: "Traits",
      },
      toForm: values => (values || []).map(t => t.key).join(", "),
      fromForm: value => value.split(/,\s*/).map(k => {return {key: k}})
    },
    {
      key: "vulnerabilities",
      default: [],
      form: {
        type: "tags",
        label: "Damage Vulnerabilities",
      }
    },
    {
      key: "resistances",
      default: [],
      form: {
        type: "tags",
        label: "Damage Resistances",
      }
    },
    {
      key: "damageImmunities",
      default: [],
      form: {
        type: "tags",
        label: "Damage Immunities",
      }
    },
    {
      key: "conditionImmunities",
      default: [],
      form: {
        type: "tags",
        label: "Condition Immunities"
      }
    },
    {
      key: "languages",
      default: [],
      form: {
        type: "tags",
        label: "Languages"
      }
    },
    {
      key: "skills",
      default: [],
      form: {
        type: "tags",
        label: "Skills",
      }
    },
    {
      key: "saves",
      default: [],
      form: {
        type: "tags",
        label: "Saves",
        suggestions: abilities
      }
    },
    {
      key: "members",
      default: ["*"]
    }
  ]
}

export const Item = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "itemType",
      form: {
        type: "select",
        label: "Name",
        options: ["Armor", "Weapon", "Other"]
      },
      table: true
    },
    {
      key: "armorBonus",
      default: 0,
      form: {
        type: "integer",
        label: "Armor Bonus"
      }
    },
    {
      key: "members",
      default: ["*"]
    }
  ]
}

export const Action = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "actionType",
      form: {
        type: "text",
        label: "Action Type",
        suggestions: [
          "Melee Weapon Attack",
          "Ranged Weapon Attack"
        ]
      },
      default: "Melee Weapon Attack",
      table: true
    },
    {
      key: "attackAbility",
      form: {
        type: "text",
        label: "Attack Ability"
      },
      suggestions: ["strength", "dexterity"],
      default: "strength",
      table: true
    },
    {
      key: "reach",
      form: {
        type: "text",
        label: "Reach"
      },
      default: 5,
      table: true
    },
    {
      key: "range",
      form: {
        type: "text",
        label: "Range"
      },
      default: 30,
      table: true
    },
    {
      key: "target",
      form: {
        type: "text",
        label: "Target"
      },
      default: "one target",
      table: true
    },
    {
      key: "damageDiceCount",
      form: {
        type: "text",
        label: "Damage Dice Count"
      },
      default: 1,
      table: true
    },
    {
      key: "damageDiceSize",
      form: {
        type: "text",
        label: "Damage Dice Size"
      },
      default: 6,
      table: true
    },
    {
      key: "damageType",
      form: {
        type: "text",
        label: "Damage Type",
      },
      default: "piercing",
      table: true
    },
    {
      key: "hitEffect",
      form: {
        type: "textarea",
        label: "Effect"
      }
    },
  ]
}

export const MonsterReaction = {
  fields: [
    {
      key: "key",
      form: {
        type: "text",
        label: "Key"
      },
      table: true
    }
  ]
}

export const MonsterTrait = {
  fields: [
    {
      key: "name",
      form: {
        type: "text",
        label: "Name"
      },
      table: true
    },
    {
      key: "description",
      form: {
        type: "textarea",
        label: "Description"
      }
    },
    {
      key: "trait",
      form: {
        type: "select",
        label: "Trait"
      }
    }
  ]
}
