import { useContext, useObjectsOfType } from '../reactDataStore'
import { Link, useLocation } from "react-router-dom"
import CampaignChildRow from "./CampaignChildRow"
import * as Schema from "./FormSchema"

function useQuery(key) {
  const params = new URLSearchParams(useLocation().search)
  return params.get(key)
}

export default function CampaignChildrenTable() {
  const type = useQuery("objectType")
  const schema = Schema[type]
  const context = useContext()
  const objects = useObjectsOfType('campaign', type)
  return (
    <div>
      <header className='d-flex'>
        <h4 className='flex-grow-1'>{type}s</h4>
        { context.isOwner ?
          <Link to={`/campaigns/${context.campaign.id}/objects/new?objectType=${type}`} className="btn btn-sm btn-secondary">
            ➕
          </Link>:
          null
        }
      </header>
      <div className='content'>
        <table className="table table-sm">
          <tbody>
            {objects.map((object) => {
              return (
                <CampaignChildRow
                  key={`object_${object.id}`}
                  type={type}
                  object={object}
                  context={context}
                  schema={schema} />

              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
