import { useObjectsOfType } from '../reactDataStore'

function isRecent(dateString) {
  if (!dateString) {
    return false
  }

  const epoch = Date.parse(dateString) + 65000
  const date = new Date(epoch)
  return date >= new Date()
}

export default function ActivePlayersPanel() {
  const activePlayers = useObjectsOfType('campaign', "Player").filter(p => isRecent(p.lastActiveAt))
  return (
    <div>
      {activePlayers.map((player) => {
        return (
          <div key={`player_${player.id}`}>
            <div className='silhouette'>👤</div>
            {player.name}
          </div>
        )
      })}
    </div>
  )
}
