import ObjectInputElement from '../ObjectInputElement'
import { Liquid } from 'liquidjs'
import { useState, useEffect } from 'react'
const liquid = new Liquid()

const capitalize = (string) => {
  string = string || ""
  if (string === "") {
    return ""
  }
  const [firstLetter, ...restOfWord] = string
  return firstLetter.toUpperCase() + restOfWord.join('')
}

export default function Trait({trait, setObject, editing, removeTrait, definitions}) {
  const [description, setDescription] = useState("")
  useEffect(() => {
    if (trait.description) {
      const template = liquid.parse(trait.description)
      liquid.render(template, {articulatedName: trait.monster.articulatedName, genericName: trait.monster.genericName})
        .then(description => setDescription(description))
    }
    else {
      setDescription("")
    }
  }, [trait.id, trait.description, trait.monster.articulatedName, trait.monster.genericName])

  const clickRemove = (e) => {
    e.preventDefault()
    removeTrait(trait.object)
  }

  if (editing) {
    return (<div className='trait-block'>
      <div className='d-flex'>
        <ObjectInputElement attribute="name" setObject={setObject} object={trait.object} placeholder={trait.name} />
        <span className='ms-auto'>
          template
          <ObjectInputElement attribute="trait" setObject={setObject} object={trait.object} options={definitions} />
        </span>
      </div>
      <ObjectInputElement attribute="description" setObject={setObject} object={trait.object} placeholder={capitalize(description)} />
      <button className='btn delete' onClick={clickRemove}>x</button>
    </div>)
  }
  else {
    return (<div className='trait-block'>
      <label>{trait.name}.</label>
      {capitalize(description)}
    </div>)
  }
}
