import Reaction from './Reaction'
import buildObject from "../FormSchemaFactory"

export default function ReactionsSection({monster, setObject, editing}) {
  const addReaction = (e) => {
    e.preventDefault()
    const reactions = monster.object.reactions || []
    reactions.push(buildObject('MonsterReaction'))
    setObject({...monster.object, reactions})
  }

  const setReaction = (reaction) => {
    const reactions = monster.object.reactions || []
    const index = reactions.findIndex(item => item.id === reaction.id)
    if (index >= 0) {
      reactions[index] = reaction
    }
    else {
      reactions.push(reaction)
    }
    setObject({...monster.object, reactions})
  }

  const removeReaction = (reaction) => {
    let reactions = monster.object.reactions || []
    reactions = reactions.filter(item => item.id !== reaction.id)
    setObject({...monster.object, reactions})
  }

  if (editing || monster.reactions.length > 0) {
    return <section>
      <div className='d-flex'>
        <h4>Reactions</h4>
        {editing ?
          <button className='btn ms-auto' onClick={addReaction}>➕</button> :
          null
        }
      </div>
      {
        monster.reactions.map((reaction, index) => {
          return <Reaction key={`reaction_${index}`} reaction={reaction} setObject={setReaction} editing={editing} removeReaction={removeReaction} />
        })
      }
    </section>
  }
  else {
    return null
  }
}
