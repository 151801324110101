import EditableField from '../EditableField'
import * as Schema from "../FormSchema"

export default function Sense({attribute, monster, setObject, editing}) {
  const value = monster[attribute]
  const schema = Schema[monster.object.type]
  const field = schema.fields.find((field) => field.key === attribute)
  if (editing || value > 0) {
    return <span>
      {field.form.label}{" "}
      <EditableField setObject={setObject} attribute={attribute} editing={editing} decorator={monster} />
      {" "}ft.{" "}
    </span>
  }
  else {
    return null
  }
}
