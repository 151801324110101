import { useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input';

export default function TagField({suggestions, values, onChange}) {
  values = values || []
  suggestions = suggestions || []
  suggestions = suggestions.map(s => {return {id: s, text: s}})
  const [tags, setTags] = useState(values.map(v => {return {id: v, text: v}}));
  const setAndEvent = (tags) => {
    setTags(tags)
    onChange(tags.map(t => t.text))
  }

  const handleDelete = i => {
    setAndEvent(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setAndEvent([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setAndEvent(newTags);
  };

  return (
    <ReactTags
      tags={tags}
      suggestions={suggestions}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      placeholder=""
      classNames={{remove: 'btn btn-sm btn-danger', tagInputField: 'form-control'}}
    />
  );
};
